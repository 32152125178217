import {
  JOBTYPE_PICKUP,
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_FAR,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_DELIVERY_STATE_NEAR,
  TRACKING_DELIVERY_STATE_UNCLEAR,
  TRACKING_STATE_BLOCKED,
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_ETA_VALID_DELTA,
  TRACKING_STATE_NOT_DELIVERED,
  TRACKING_STATE_NOT_PICKED_UP,
  TRACKING_STATE_OUT_FOR_DELIVERY,
  TRACKING_STATE_PICKED_UP,
  TRACKING_STATE_SHADOWED,
  TRACKING_STATE_THRESHOLD_BLURRY,
  TRACKING_STATE_THRESHOLD_MIN,
  TRACKING_STATE_UNKNOWN
} from './constants';
import moment from 'moment-timezone';
import config from '@/config/defaultConfig';
import { sendAnalytics } from '@/services/analytics';

const getEtaTimestampDelta = timestamp => {
  const now = Date.now();
  return new Date(timestamp).getTime() - now;
};

const getters = {
  getThemeId: state => state.themeId,
  isThemeBvb: state => getters.getThemeId(state) === 'bvb',
  getDeliveryStatusDetail: state => {
    return state.trackingData.status?.detail || null;
  },
  hasGdprConsent: state => tenantId => {
    return state.gdprConsent[tenantId];
  },
  getPrivateTrackingState: state => state.isPrivate,
  getTrackingData: state => {
    return state.trackingData;
  },
  isServiceTypePickup: state => state.trackingData.jobType === JOBTYPE_PICKUP,
  getTrackingState: state => {
    const tracking = state.trackingData.status;
    const TRACKING_STATE_THRESHOLD_MED =
      state.trackingData.maxStopsToShowDriverLocation;
    const trackingType = tracking.type;
    const stops =
      typeof tracking.remainingStops === 'number'
        ? tracking.remainingStops
        : undefined;
    const delivered = trackingType === TRACKING_STATE_DELIVERED;
    const outForDelivery = trackingType === TRACKING_STATE_OUT_FOR_DELIVERY;
    const notDelivered = trackingType === TRACKING_STATE_NOT_DELIVERED;
    const unknown = trackingType === TRACKING_STATE_UNKNOWN;
    const blocked = trackingType === TRACKING_STATE_BLOCKED;
    const shadowed = state.trackingData.tourMode === TRACKING_STATE_SHADOWED;
    const negativeStopsExceeded =
      Math.sign(stops) === -1 && stops < TRACKING_STATE_THRESHOLD_BLURRY;
    const moreThan15MinAway =
      getEtaTimestampDelta(tracking.etaTimestamp) >
      -TRACKING_STATE_ETA_VALID_DELTA;

    if (getters.isServiceTypePickup(state)) {
      if (delivered) {
        return TRACKING_STATE_PICKED_UP;
      }

      if (notDelivered) {
        return TRACKING_STATE_NOT_PICKED_UP;
      }
    }

    if (notDelivered) {
      return TRACKING_STATE_NOT_DELIVERED;
    }

    if (delivered) {
      return TRACKING_STATE_DELIVERED;
    }

    if (blocked) {
      sendAnalytics(state.trackingData, 'tracking_state', 'blocked', {
        reason: 'blocked status from backend'
      });
      return TRACKING_STATE_BLOCKED;
    }

    if (outForDelivery) {
      if (shadowed) {
        if (
          getEtaTimestampDelta(tracking.etaTimestampMax) <
          TRACKING_STATE_ETA_VALID_DELTA
        ) {
          sendAnalytics(state.trackingData, 'tracking_state', 'unknown', {
            reason:
              'etaTimestampMax more than 15mins in the past (shadowed tour)'
          });
          return TRACKING_STATE_UNKNOWN;
        }
        return TRACKING_STATE_SHADOWED;
      }

      if (
        getEtaTimestampDelta(tracking.etaTimestamp) <
        TRACKING_STATE_ETA_VALID_DELTA
      ) {
        sendAnalytics(state.trackingData, 'tracking_state', 'unknown', {
          reason: 'etaTimestamp more than 15mins in the past'
        });
        return TRACKING_STATE_UNKNOWN;
      }

      if (
        stops < state.trackingData.trackingStateThresholdMax &&
        stops > TRACKING_STATE_THRESHOLD_MED
      ) {
        return TRACKING_DELIVERY_STATE_NEAR;
      }
      if (
        stops <= TRACKING_STATE_THRESHOLD_MED &&
        (stops > TRACKING_STATE_THRESHOLD_MIN || moreThan15MinAway)
      ) {
        return TRACKING_DELIVERY_STATE_CLOSE;
      }
      if (
        stops <= TRACKING_STATE_THRESHOLD_MIN &&
        stops >= TRACKING_STATE_THRESHOLD_BLURRY
      ) {
        return TRACKING_DELIVERY_STATE_LAST;
      }
      if (stops >= state.trackingData.trackingStateThresholdMax) {
        return TRACKING_DELIVERY_STATE_FAR;
      }
      if (negativeStopsExceeded) {
        return TRACKING_DELIVERY_STATE_UNCLEAR;
      }
      if (tracking.etaTimestamp === null) {
        return TRACKING_DELIVERY_STATE_UNCLEAR;
      }
    }

    if (unknown) {
      sendAnalytics(state.trackingData, 'tracking_state', 'unknown', {
        reason: 'unknown status from backend'
      });
      return TRACKING_STATE_UNKNOWN;
    }

    return TRACKING_DELIVERY_STATE_UNCLEAR;
  },
  showPositionExplanation: state =>
    getters.getTrackingState(state) === TRACKING_DELIVERY_STATE_NEAR &&
    state.trackingData.toAddress,
  getTimeframe: state => {
    const { etaTimestampMin: from, etaTimestampMax: to } =
      state.trackingData?.status || {};
    if (!from || !to) return false;

    return { from, to };
  },
  getMinutesLeft: state => {
    let response = {
      from: null,
      to: null,
      format: null
    };

    const { etaTimestampMin, etaTimestampMax } =
      state.trackingData?.status || {};
    if (!etaTimestampMin || !etaTimestampMax) return response;

    const from = moment(etaTimestampMin);
    const to = moment(etaTimestampMax);

    const now = moment();
    const durationStart = moment.duration(from.diff(now));
    const durationEnd = moment.duration(to.diff(now));

    if (durationStart.asMinutes() > 0 && durationStart.asMinutes() <= 60) {
      response = {
        from: Math.ceil(durationStart.asMinutes()),
        to: Math.ceil(durationEnd.asMinutes()),
        format: 'min'
      };
    }
    if (durationStart.asMinutes() > 60) {
      response = {
        from: moment.utc(durationStart.asMilliseconds()).format('h:mm'),
        to: moment.utc(durationEnd.asMilliseconds()).format('h:mm'),
        format: 'h'
      };
    }
    return response;
  },
  getReviewAppId: () => config.reviewAppId
};

export default getters;
