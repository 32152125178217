import { getCountry, getDomain, getUtmSource } from '@/router/queryParam';
import { configPromises } from './themeState';

export const defaultThemeName = () =>
  getDomain() === 'rtt.bettermile.com' ? 'bettermile' : 'gls';
export const whiteListedThemeIds = [
  'bmdemo-global',
  'bvb',
  'gls',
  'gls-it',
  'gls-one.de',
  'royalmail-uk',
  'royalmail-uk-test'
];
const themeMapping = {
  'bmdemo-global': 'bettermile',
  'gls-one.de': 'gls-one',
  'royalmail-uk-test': 'royalmail-uk'
};

const countryToThemeMapping = {
  IT: 'gls-it'
};

export const getThemeIdFromApiResponse = trackingData => {
  const fromApi = trackingData.shipperId || trackingData.tenantId;
  return getThemeIdFrom(fromApi);
};

export const getThemeIdFrom = utmSource =>
  whiteListedThemeIds.includes(utmSource) &&
  (themeMapping[utmSource] || utmSource);

export const getThemeIdFromCountry = country => countryToThemeMapping[country];

export const getThemeIdFromStateAndRoute = (state, route) => {
  return (
    getThemeIdFrom(getUtmSource(route)) ||
    getThemeIdFromApiResponse(state.trackingData) ||
    getThemeIdFromCountry(getCountry(route))
  );
};

export const getThemeIdFromRoute = route =>
  getThemeIdFrom(getUtmSource(route)) ||
  getThemeIdFromCountry(getCountry(route));

export default async function loadConfigByThemeId(themeId) {
  if (configPromises[themeId]) return configPromises[themeId];
  const configPromise = _loadConfigByThemeId(themeId);
  configPromises[themeId] = configPromise;
  return configPromise;
}

async function _loadConfigByThemeId(themeId) {
  if (!themeId) return { slots: [] };
  const config = await import(
    /* webpackChunkName: "[request]" */ `./themes/${themeId}.js`
  );

  if (!config) {
    // eslint-disable-next-line no-console
    console.error(`${themeId} has no config. Please provide a correct themeId`);
    return { slots: [] };
  }
  return config.default;
}

export function setFavicons(themeId) {
  let themeName = '';

  if (themeId) {
    themeName = `/themes/${themeId}`;
  }

  const favicons = [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `${themeName}/favicon/favicon-16x16.png`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `${themeName}/favicon/favicon-32x32.png`
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: `${themeName}/favicon/favicon.ico`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: `${themeName}/favicon/android-chrome-192x192.png`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '512x512',
      href: `${themeName}/favicon/android-chrome-512x512.png`
    },
    {
      rel: 'apple-touch-icon',
      href: `${themeName}/favicon/apple-touch-icon.png`
    },
    {
      rel: 'manifest',
      href: `${themeName}/favicon/site.webmanifest?v=20210917`
    },
    {
      rel: 'shortcut icon',
      href: `${themeName}/favicon/favicon.ico?v=20210917`
    }
  ];

  favicons.forEach(({ rel, type, sizes, href }) => {
    const link = document.createElement('link');
    link.rel = rel;
    if (type) link.type = type;
    if (sizes) link.sizes = sizes;
    link.href = href;
    document.head.appendChild(link);
  });
}
