import { idbKeyVal as storage } from '@/util/storage';
import { configGetter } from '@/config/configGetter.js';

const supportsServiceWorker = 'serviceWorker' in navigator;
const supportsPushManager = 'PushManager' in window;
const supportsNotification = 'Notification' in window;
export const supportsPushNotification =
  supportsNotification && supportsPushManager && supportsServiceWorker;

async function registerServiceWorker() {
  try {
    const registration =
      await navigator.serviceWorker.register('/push-worker.js');
    return registration;
  } catch (err) {
    throw new Error(`Unable to register service worker. ${err.message}`);
  }
}

export class NotificationPermissionError extends Error {}

async function requestNotificationPermission() {
  const permissionResult = await Notification.requestPermission();
  if (permissionResult !== 'granted') {
    throw new NotificationPermissionError(
      `User did not grant permission with ${permissionResult}.`
    );
  }
}

export async function subscribe(params) {
  const timerStart = performance.now();
  const getElapsedTime = () => performance.now() - timerStart;
  const registration = await registerServiceWorker().catch(err => {
    err.timing = getElapsedTime();
    throw err;
  });
  await requestNotificationPermission().catch(err => {
    err.timing = getElapsedTime();
    throw err;
  });

  const subscriptionOptions = {
    userVisibleOnly: true,
    applicationServerKey: import.meta.env.VITE_APP_PUSH_SERVER_PUBLIC_KEY
  };
  const subscription = await registration.pushManager
    .subscribe(subscriptionOptions)
    .catch(err => {
      err.timing = getElapsedTime();
      throw err;
    });

  await storage.set(
    'last-pn-sub',
    JSON.stringify({
      updatePath:
        `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/notification/push/update`
          .replace('{tenant}', params.tenantId)
          .replace('{trackingId}', params.trackingId)
          .replace('{postcode}', params.postcode),
      options: subscriptionOptions,
      endpoint: subscription.endpoint
    })
  );

  const conv = val =>
    btoa(String.fromCharCode.apply(null, new Uint8Array(val)));
  const baseHostname = new URL(
    parent !== window ? document.referrer : document.location.href
  ).hostname;

  return fetch(
    `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/notification/push`
      .replace('{tenant}', params.tenantId)
      .replace('{trackingId}', params.trackingId)
      .replace('{postcode}', params.postcode),
    {
      method: 'POST',
      body: JSON.stringify({
        endpoint: subscription.endpoint,
        authToken: conv(subscription.getKey('auth')),
        publicKey: conv(subscription.getKey('p256dh')),
        language: params.language,
        minutesBeforeDelivery: params.minutesBeforeDelivery
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-original-hostname': baseHostname
      }
    }
  );
}
