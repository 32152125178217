export default Object.fromEntries(
  [
    'cs',
    'da',
    'de',
    'en',
    'es',
    'fr',
    'hr',
    'hu',
    'it',
    'nl',
    'pl',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr'
  ].map(lang => [lang, lang])
);
