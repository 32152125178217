<template>
  <v-col class="pa-0" v-if="trackingData.customizedDelivery">
    <div :class="isBelowMobileBreakpoint ? 'py-2' : 'py-4'">
      <span class="font-weight-bold">{{
        $t('customization.display.header')
      }}</span>
    </div>
    <v-card
      v-if="trackingData.customizedDelivery.type !== DELIVERY_NOTE"
      class="card_small mx-0 v-col-12 pa-4"
      variant="flat"
    >
      <p class="pa-0">
        <span
          v-if="trackingData.customizedDelivery.type === DELIVER_TO_NEIGHBOUR"
          class="font-weight-bold"
        >
          {{ $t('customization.neighbour.header') }}
        </span>
        <span
          v-if="trackingData.customizedDelivery.type === DEPOSIT_PERMISSION"
          class="font-weight-bold"
        >
          {{ $t('customization.dropOffPermission.header') }}
        </span>
        <br />
        {{ trackingData.customizedDelivery.location
        }}{{ trackingData.customizedDelivery.fullName }}<br />
        {{ trackingData.customizedDelivery.description }}
      </p>
      <img src="/check_pad.png" />
    </v-card>
    <DeliveryNote
      v-else-if="trackingData.customizedDelivery.type === DELIVERY_NOTE"
      :delivery-note="trackingData.customizedDelivery.description"
    />
  </v-col>
</template>

<script>
import {
  DELIVER_TO_NEIGHBOUR,
  DELIVERY_NOTE,
  DEPOSIT_PERMISSION
} from '@/store/constants';
import { mapState } from 'vuex';
import DeliveryNote from '@/components/DeliveryNote.vue';

export default {
  name: 'CustomizedDeliveryData',
  components: { DeliveryNote },
  computed: {
    ...mapState(['isBelowMobileBreakpoint', 'trackingData'])
  },
  data() {
    return {
      DELIVER_TO_NEIGHBOUR,
      DEPOSIT_PERMISSION,
      DELIVERY_NOTE
    };
  }
};
</script>

<style scoped>
.card_small {
  display: flex;
  justify-content: space-between;
}

img {
  height: 5em;
  align-self: center;
  margin-left: 1em;
}
</style>
