<template>
  <div>
    <v-card class="mx-0 v-col-12 card_small" variant="flat">
      <div class="pa-0 d-flex flex-column gap-1">
        <p class="font-weight-bold">
          {{ $t('customization.deliveryHints.header') }}
        </p>
        <p>{{ deliveryNote }}</p>
      </div>
      <img src="/delivery_note_pad.png" alt="" />
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DeliveryNote',
  props: {
    deliveryNote: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['isBelowMobileBreakpoint'])
  }
};
</script>

<style scoped>
.card_small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

img {
  height: 5em;
  align-self: center;
  margin-left: 1em;
}
</style>
