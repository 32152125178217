<template>
  <div>
    <div
      v-if="!isBelowMobileBreakpoint && !$store.$isWidget"
      class="v-row v-col-12"
    >
      <h2 class="text-h3 pa-0 pb mr-4 my-4">
        {{ $t('overview.header') }}
      </h2>
    </div>
    <div v-if="isBelowMobileBreakpoint && !$store.$isWidget" class="v-row my-0">
      <v-col
        :style="$rttI18n.isGlsItSelected ? { visibility: 'hidden' } : null"
        class="d-flex text-right align-center font-weight-bold"
      >
        <div>{{ $t('overview.title') }}</div>
      </v-col>
      <v-col class="d-flex align-center justify-end" style="flex-basis: 50%">
        <span class="font-weight-bold">{{ $t('generic.trackingId') }}</span
        >: {{ trackingData.trackingId }}
      </v-col>
    </div>
    <v-row class="v-col-12 pa-0 ma-0" data-testId="RealTimeTrackingInProgress">
      <v-col
        v-if="
          isBelowMobileBreakpoint &&
          !configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview
        "
        class="v-col-12 pa-0"
      >
        <OverviewComponent v-bind="$attrs" />
        <FeedbackComponent
          v-if="this.$rttI18n.isGlsPlSelected"
          :ratingData="trackingData.rating"
          :trackingId="trackingData.trackingId"
          :postcode="trackingData.toAddress.postcode"
        />
      </v-col>
      <v-col
        class="pa-0"
        :class="isBelowMobileBreakpoint ? 'v-col-12' : 'v-col-6'"
      >
        <MapContainer />
        <AdComponent
          v-if="
            !isBelowMobileBreakpoint &&
            ((trackingData.ad && trackingData.ad.length) || 0) < 2 &&
            !configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview
          "
          :adData="trackingData.ad"
        />
      </v-col>
      <v-col
        v-if="!configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview"
        :class="isBelowMobileBreakpoint ? 'v-col-12 pa-0' : 'v-col-6 pa-0 pl-4'"
      >
        <OverviewComponent v-if="!isBelowMobileBreakpoint" v-bind="$attrs" />
        <FeedbackComponent
          v-if="this.$rttI18n.isGlsPlSelected"
          :ratingData="trackingData.rating"
          :trackingId="trackingData.trackingId"
          :postcode="trackingData.toAddress.postcode"
        />
        <CustomizedDeliveryData
          v-if="trackingData.customizedDelivery && trackingData.toAddress"
        />
        <AdComponent v-if="isBelowMobileBreakpoint" :adData="trackingData.ad" />
        <DeliveryAddressComponent />
      </v-col>
    </v-row>
    <AdComponent
      v-if="
        !isBelowMobileBreakpoint &&
        (trackingData.ad && trackingData.ad.length) >= 2
      "
      :adData="trackingData.ad"
    />
  </div>
</template>

<script>
import DeliveryAddressComponent from '../DeliveryAddressComponent';
import MapContainer from '../map/MapContainer';
import OverviewComponent from '../OverviewComponent';
import CustomizedDeliveryData from '../CustomizedDeliveryData';
import AdComponent from '../AdComponent';
import { configGetter } from '@/config/configGetter';
import { mapGetters, mapState } from 'vuex';
import FeedbackComponent from '@/components/feedback/FeedbackComponent.vue';

export default {
  inheritAttrs: false,
  name: 'RealTimeTrackingInProgress',
  components: {
    FeedbackComponent,
    CustomizedDeliveryData,
    DeliveryAddressComponent,
    MapContainer,
    OverviewComponent,
    AdComponent
  },
  data() {
    return {
      configGetter
    };
  },
  computed: {
    ...mapState(['trackingData', 'isBelowMobileBreakpoint']),
    ...mapGetters(['getThemeId'])
  }
};
</script>

<style scoped></style>
