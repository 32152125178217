import defaultConfig from '@/config/defaultConfig';
import tenants from '@/config/tenants.js';

export const configGetter = (tenantId, themeId) => ({
  ...(tenants[tenantId] || {}),
  ...(defaultConfig.themeConfig[themeId] || {}),
  ...defaultConfig.widgetConfiguration,
  API_URL:
    defaultConfig.widgetConfiguration?.trackingApiUrl ||
    import.meta.env.VITE_APP_API_URL ||
    window.location.origin + '/v1'
});
