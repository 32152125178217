import { configGetter } from '@/config/configGetter.js';

export function sendAnalytics(trackingData, category, name, payload) {
  const body = {
    category,
    name,
    payload,
    createdAt: new Date(),
    trackingId: trackingData.trackingId,
    parcelNumber: trackingData.parcelNumber,
    tenantId: trackingData.tenantId
  };
  const blob = new Blob([JSON.stringify(body)], { type: 'application/json' });
  navigator.sendBeacon(`${configGetter().API_URL}/analytics`, blob);
}
