<template>
  <v-checkbox
    v-model="consent"
    @update:model-value="setGlobalConsentState"
    color="anchor"
    required
  >
    <template v-slot:label>
      <span
        v-html="$rttI18n.tenantSpecificContent('consentDeclaration')"
        v-on:click.stop
      />
    </template>
  </v-checkbox>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'GDPRConsentComponent',
  data() {
    return {
      consent: null
    };
  },
  computed: {
    ...mapState(['gdprConsent'])
  },
  methods: {
    ...mapActions(['setGdprConsent']),
    updateLocalConsentState(checked) {
      this.consent = !!this.gdprConsent[this.$rttI18n.tenantId];
    },
    setGlobalConsentState(checked) {
      this.setGdprConsent({
        tenantId: this.$rttI18n.tenantId,
        value: checked
      });
    }
  },
  watch: {
    gdprConsent() {
      this.updateLocalConsentState();
    },
    '$rttI18n.tenantId'() {
      this.updateLocalConsentState();
    }
  },
  mounted() {
    this.updateLocalConsentState();
  }
};
</script>

<style scoped></style>
